import * as React from "react";
import AprRatesContainer from "../containers/apr-rates";
import DefaultLayout from "../layouts/default";
export default () => {

  return (
    <>
      <DefaultLayout animatedHeader title="APR Rates">
        <AprRatesContainer />
      </DefaultLayout>
    </>
  );
};
