import { Link } from "gatsby";
import React, { ReactElement } from "react";
import Heading from "../../components/heading";
import AprRates from "../../hoc/apr-rates";
import FilledButton from "../../hoc/filled-button";


export interface AprRatesContainerProps { }


export default function AprRatesContainer(
  props: AprRatesContainerProps
): ReactElement | null {

  return (
    <>
      <div id="apr-rates" className="mb-16">
        <div className="text-center  my-8">
          <Heading position="center">
            <h2>APR RATES</h2>
          </Heading>
          <Link to="/apply" className="mt-8 flex justify-center">
            <FilledButton>
              REQUEST FUNDS NOW
            </FilledButton>
          </Link>
        </div>
        <AprRates />
      </div>
    </>
  )
}
