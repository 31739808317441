import React from 'react'
import Tabs from "../../components/tabs"
type Props = {
    children?: any;
}

function AprRates({
    children
}: Props) {
    return (
        <div className='-max-w-1366 mx-auto'>
            <div className='py-16 px-2 md:px-0'>
                <div style={{
                    border: "1px solid #f1f1f1"
                }}>
                    <Tabs >

                        <div >
                            <h4 className="my-4">

                                Annual Percentage Rate(APR) Disclosure &amp; Range(Qualified Customers)
                            </h4>

                            <p>
                                Annual percentage rate(APR) refers to the yearly interest generated by a sum that's charged to borrowers. APR is expressed as a percentage that represents the actual yearly cost of funds over the term of a loan. This includes any fees or additional costs associated with the transaction. The APR provides consumers with a bottom-line number they can compare among lenders, credit cards, or investment products. Lending-Ground cannot guarantee any APR since we are not a lender ourselves. An APR can generally run between 5.99% up to 35.99%. Loan products general have a 61-day minimum repayment term and a 84-month maximum repayment term. Before accepting a loan from a lender within our network, please read the loan agreement carefully as the APR and repayment terms may differ from what is listed on this site.</p>

                            <h4 className="my-4">
                                Representative Examples of APR, Total Loan Costs &amp; Fee(Qualified Customers)</h4>

                            <table className='w-full'>

                                <tbody>

                                    <tr className='font-bold'>

                                        <td>

                                            Amount
                                        </td>

                                        <td>

                                            Period</td>
                                        <td>
                                            APR</td>
                                        <td>
                                            Monthly</td>
                                        <td>
                                            Total Paid</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            $2,000</td>
                                        <td>
                                            12 mo</td>
                                        <td>
                                            24%</td>
                                        <td>
                                            $189.12</td>
                                        <td>
                                            $2,269.44</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            $4,000</td>
                                        <td>
                                            24 mo</td>
                                        <td>
                                            12%</td>
                                        <td>
                                            $188.29</td>
                                        <td>
                                            $4,518.96</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            $6,000</td>
                                        <td>
                                            36 mo</td>
                                        <td>
                                            12%</td>
                                        <td>
                                            $199.29</td>
                                        <td>
                                            $7,174.29</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </Tabs>

                </div>
            </div>
        </div>
    )
}

export default AprRates